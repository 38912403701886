.loader{
  &:not(.no-parents){
    //position: absolute;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    transform: perspective(1px);
    display: flex;
    align-items: center;
    justify-content: center;

    &::before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(#1E2534, 0.35);
      backdrop-filter: blur(10px);
      opacity: 1;
      pointer-events: none;
      z-index: -1;
    }
  }

  //span{
  //  position: relative;
  //  display: block;
  //  opacity: 1;
  //  border-radius: 100%;
  //  width: 60px;
  //  height: 60px;
  //  border-top: 6px solid #57A051;
  //  border-left: 6px solid transparent;
  //  border-right: 6px solid transparent;
  //  border-bottom: 6px solid transparent;
  //  transform: rotate(0);
  //  animation: spinLoader 0.6s ease-out infinite;
  //
  //  &.md{
  //    width: 25px;
  //    height: 25px;
  //    border-width: 3px;
  //  }
  //
  //  &.lg{
  //    width: 35px;
  //    height: 35px;
  //    border-width: 4px;
  //  }
  //}

  &-wrapper{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 300ms ease;
    opacity: 0;
    pointer-events: none;
    z-index: 999999999;
    //transition-delay: 500ms;
    &.active{
      pointer-events: auto;
      opacity: 1;
      //transition-delay: 0ms;
    }
  }
}


@keyframes spinLoader {
  100%{
    transform: rotate(360deg);
  }
}
