@import "./variables";
h1,.h1{
  font-weight: 600;
  font-size: 64px;
  line-height: 72px;
  color: $mainColor;
  margin-bottom: 24px;
  @media (max-width: 767px) {
    font-size: 40px;
    line-height: 48px;
  }
}
h2,.h2{
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  color: $mainColor;
  @media (max-width: 990px) {
    font-size: 32px;
    line-height: 40px;
  }
  &.h2-app{
    font-size: 32px;
    line-height: 40px;
    @media (max-width: 991px){
      font-size: 28px;
      line-height: 32px;
    }
  }
}
h3, .h3{
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: $blackColor;
}
h4, .h4{
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: $blackColor;
}
h5, .h5{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $blackColor;
}
a {
  transition: all 400ms;
}
.muted {
  color: $mutedColor;
}

p, a, li{
  color: $blackColor;
  &.subtitle{
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
  }
  &.medium{
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  &.medium-app{
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
  &.small{
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
  }
  &.little{
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
  &.little{
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
  }
}
.text-right {
  text-align: right;
}
