.select-custom {
  @media (max-width: 767px) {
    align-self: flex-end;
    margin-top: 20px;

  }
}
.select-custom{
  min-width: 142px;
  position: relative;
  &_top{
    font-size: 14px;
    line-height: 18px;
    padding: 11px 35px 11px 16px;
    color: #444;
    border-radius: 12px;
    border: none;
    background-color: #fff;
    height: 40px;
    position: relative;
    z-index: 4;
    cursor: pointer;

    &::after{
      content: "\e916";
      position: absolute;
      font-size: 10px;
      font-family: icomoon!important;
      bottom: 0;
      right: 16px;
      display: flex;
      align-items: center;
      height: 100%;
      width: 10px;
      transform: scale(1, -1);
    }
  }

  &_body{
    width: 100%;
    padding-top: 40px;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 16px;
    overflow: hidden;
    background: #fff;
    ul{
      max-height: 200px;
      overflow-y: auto;
      padding-bottom: 10px;
    }
    li{
      cursor: pointer;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 18px;
      background: #fff;
      border: none;
      &:hover{
        background-color: #F2F4F5;
      }
    }
  }
}
