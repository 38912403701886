@import "../../../assets/styles/variables";

.notification_status{
  position: fixed;
  max-width: 600px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  bottom: 100px;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;

  img{
    cursor: pointer;
  }

  &.green{
    color: $greenColor;
  }
  &.error{
    color: $redColor;
  }
}
