.popup-wrapper {
  &.popup-text {
    .popup {
      width: calc(100% - 160px);
      max-width: 888px;
      @media (max-width: 767px) {
        width: 100%;
        max-width: calc(100% - 80px);
      }
      @media (max-width: 575px) {
        max-width: calc(100% - 40px);
      }
      .popup-content {
        text-align: left;
        width: 100%;
        padding: 64px;
        @media (max-width: 767px) {
          padding: 40px;
        }
        @media (max-width: 575px) {
          padding: 40px 20px ;
        }
      }
      .h3 {
        margin-bottom: 24px;
      }
      h4 {
        margin-bottom: 8px;
        &.text-underline {
          text-decoration: underline;
        }
      }
      p {
        margin-bottom: 24px;
      }
      .h4 {
        margin-bottom: 8px;
      }
    }
    .close-modal {
      position: absolute;
      right: 24px;
      top: 24px;
      background: transparent;
      border: none;
      font-size: 16px;
      padding: 0;
      color: #8F929A;
      cursor: pointer;
      transition: all 400ms;
      &:hover {
        color: #78BD54;
      }
    }
    .scroll-content {
      height: calc(100vh - 350px);
    }
    .ScrollbarsCustom-Scroller {
      padding-right: 35px !important;
    }
  }
  &.modal-blur {
    background: transparent;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      right: 0;
      background: rgba(#1E2534, 0.5);
      backdrop-filter: blur(15px);
      opacity: 1;
      pointer-events: none;
    }
  }
}
.modal-content {
  img {
    margin-bottom: 24px;
  }
  .h3 {
    margin-bottom: 24px;
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: #8F929A;
  }
  .button-group {
    [class*='btn'] {
      & + [class*='btn'] {
        margin-left: 16px;
      }
    }
  }
}