.intercom-launcher {
  opacity: 0!important;
  pointer-events: none;
  z-index: -2 !important;
}
.intercom-launcher.intercom-launcher-active {
  opacity: 1;
  pointer-events: auto;
}

@import "fonts";
@import "variables";
@import "../fonts/sparrow-icon/style.css";
@import "degree-blocks";
@import "ssn";
@import "select";

*:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
a{
  text-decoration: none;
  color: inherit;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
img{
  display: block;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
button{
  border:none;
  cursor:pointer;
  background-color: transparent;
  font-family: inherit;
  margin: 0;
}
input[type=text], input[type=email], input[type=submit], textarea{
  -webkit-appearance: none;
  outline: none;
  font-family: inherit;
  margin: 0;
}
* {
  box-sizing: border-box;
}


/*Main blocks */
body{
  margin:0;
  padding: 0;
  font-family: 'Basier Circle', sans-serif;
  background-color: #F2F4F5;
  color: $mainColor;
  font-size: 16px;
  line-height: 24px;
}
.wrapper{
  //display: flex;
  //flex-direction: column;
  //min-height: 100vh;
  //overflow-x: hidden;
}
.content{
  flex: 1 0 auto;
}
.container{
  max-width: 1220px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 1270px) {
    max-width: calc(100% - 100px);
  }
  @media (max-width: 990px) {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 767px) {
    //max-width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
}
.overflow-hidden {
  overflow: hidden !important;
}
.row  {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
}
.col {
  padding-left: 15px;
  padding-right: 15px;
}
.col-3 {
  flex: 0 0 25%;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 990px) {
    flex: 0 0 50%;
    width: 50%;
  }
}
.col-4 {
  flex: 0 0 33.3333%;
  width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-6 {
  flex: 0 0 50%;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.text-center {
  text-align: center;
}
.pl-20px {
  padding-left: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
.text-green{
  color: $greenColor;
}
.text-dark-green{
  color: $darkGreenColor;
}
.text-red{
  color: $redColor;
}
.text-dark-red{
  color: $darkRedColor;
}
.text-yellow{
  color: $yellowColor;
}
.text-orange{
  color: $orangeColor;
}
.text-blue{
  color: $blueColor;
}
.text-grey{
  color: $greyColor;
}
.back-green{
  background-color: $darkGreenColor;
  color: $whiteColor;
}
.back-grey{
  background-color: #E8E9EB;
  color: $mainColor;
}

.text-right {
  text-align: right;
}


h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

.h1, .h2, h1, h2 {
  font-weight: 600;
  color: #1E2534;
}

.h1, h1 {
  font-size: 64px;
  line-height: 72px;
  margin-bottom: 24px;
}

.h2, h2 {
  font-size: 40px;
  line-height: 48px;

  @media (max-width: 990px){
    font-size: 32px;
    line-height: 40px;
  }

  &.h2-app{
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 8px;
    @media (max-width: 991px){
      font-size: 28px;
      line-height: 32px;
    }
  }
}

.h3, h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.h4, h4 {
  font-size: 16px;
  line-height: 20px;
  color: #000;
  font-weight: 600;
}

.muted {
  color: #8E9399;
}

a {
  text-decoration: none;
  transition: all .4s;
}

a.little, li.little, p.little {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}

.layout-row {
  display: flex;
}

.space-between{
  justify-content: space-between;
}

.layout-column{
  display: flex;
  flex-direction: column;
}

.align-center{
  align-items: center;
}
.justify-center{
  justify-content: center;
}

.head-info{
  width: 100%;
  @media (max-width: 990px) {
    .h2-app {
      margin-bottom: 0;
    }
  }
  .menu-dots{
    background-color: transparent;
  }
}

.mb-0{
  margin-bottom: 0!important;
}

.h3 + .muted {
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
  color: #8F929A;
  margin-bottom: 24px;
}

.connect-card{
  background: $whiteColor;
  box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
  border-radius: 16px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  margin-bottom: 40px;
  @media (min-width: 767px) and (max-width: 1024px){
    order: 3;
    width: calc(50% - 16px);
    margin-right: 32px;
    justify-content: space-between;
    &:last-child{
      margin-right: 0;
      justify-content: flex-start;
    }
  }
  img{
    max-height: 160px;
    margin-bottom: 16px;
    margin-top: 8px;
  }
  h4{
    font-weight: 600;
    margin: 16px 0 8px;
  }
  .btn-primary{
    margin-top: 24px;
    @media (max-width: 767px){
      margin-top: 16px;
    }
  }
  .close{
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}


.password-verification {
  list-style: none;
  padding: 0;
  margin:  32px 0 0 0;
  li {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #8F929A;
    display: flex;
    align-items: center;
    & + li {
      margin-top: 8px;
    }
    span {
      width: 16px;
      height: 16px;
      transition: opacity 400ms;
      opacity: .5;
      background: url(../../assets/images/check.svg) center center no-repeat;
      background-size: 100% auto;
      margin-right: 8px;
    }
    &.valid {
      span {
        opacity: 1;
      }
    }
  }
}

.page-slider-wrapper {
  &.normalScroll {
    //.fullpage-wrapper {
    //  height: auto !important;
    //  transform: none !important;
    //  .fp-section {
    //    height: auto !important;
    //    position: relative;
    //  }
    //}
  }
}
//body {
//  &.fp-responsive {
//    overflow: visible !important;
//    height: initial !important;
//    .fp-scroller {
//      height: auto !important;
//      transform: translate(0,0) !important;
//    }
//    .iScrollVerticalScrollbar {
//      display: none!important;
//    }
//  }
//}
