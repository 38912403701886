@import "../../../../assets/styles/variables";

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-right: -16px;

  .clear-btn{
    cursor: pointer;
  }
}

.form-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  width: 100%;
}

.form-col-6 {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 50%;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  width: 50%;
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    & + .form-col-6 {
      margin-top: 32px !important;
    }
  }
}

.form-col {
  padding-left: 16px;
  padding-right: 16px;
}

.form-group {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  margin-bottom: 20px;

  &.has-prefix {
    .prefix{
      position: absolute;
      left: 0;
      bottom: 15px;
    }

    input {
      transition: all .0s ease!important;
    }
    input.has-value{
      padding-left: 20px!important;
    }
  }

 input[type=text],
 input[type=number],
 input[type=email],
 input[type=tel],
 input[type=password] {
    //font-weight: 400;
    //font-size: 16px;
    //line-height: 20px;
    //font-family: inherit;
    //border: none;
    //border-bottom: 1px solid rgba(30,37,52,.2);
    //background-color: transparent;
    //padding: 8px 0 16px;
    //width: 100%;
    //transition: all .3s ease;
    //border-radius: 0;
   &:focus, &:hover{
     border-bottom: 1px solid $mainColor;
     &+label{
       color: $mainColor;
     }
   }
   &::placeholder{
     color: rgba(30, 37, 52, 0.25);
   }
  }

  label:not(.error) {
    font-size: 14px;
    line-height: 18px;
    color: rgba(30,37,52,.5);
    display: block;
    -webkit-transition: all .4s ease;
    -o-transition: all .4s ease;
    transition: all .4s ease;
  }

  &--navigation {
    position: absolute;
    right: 0;
    top: 28px;
    display: flex;
    align-items: center;
  }

  &.error input{
    border-bottom: 1px solid #ED1C24!important;
  }

  .error-text,
  label.error:not(input) {
    color: #ED1C24!important;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    margin-top: 8px;
    order: -1;
  }

  .input-success{
    width: 24px;
    height: 24px;
  }
}

.form-row .form-group {
  margin-bottom: 0;
}

.form-group--navigation .toggle-password {
  background-image: url(../../../../assets/images/view.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 16px;
  height: 16px;
  opacity: .5;

  &.active {
    background-image: url(../../../../assets/images/view-active.svg);
  }
}
