@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?z0167v');
  src:  url('fonts/icomoon.eot?z0167v#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?z0167v') format('truetype'),
    url('fonts/icomoon.woff?z0167v') format('woff'),
    url('fonts/icomoon.svg?z0167v#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bag:before {
  content: "\e92d";
}
.icon-egg:before {
  content: "\e92e";
}
.icon-eggs:before {
  content: "\e92f";
}
.icon-money-bag:before {
  content: "\e930";
}
.icon-profile:before {
  content: "\e931";
}
.icon-logo:before {
  content: "\e92c";
}
.icon-blog:before {
  content: "\e920";
}
.icon-career:before {
  content: "\e921";
}
.icon-chat:before {
  content: "\e922";
}
.icon-debt:before {
  content: "\e923";
}
.icon-faq:before {
  content: "\e924";
}
.icon-group:before {
  content: "\e925";
}
.icon-institute:before {
  content: "\e926";
}
.icon-invest:before {
  content: "\e927";
}
.icon-press:before {
  content: "\e928";
}
.icon-refi:before {
  content: "\e929";
}
.icon-student-pay:before {
  content: "\e92a";
}
.icon-universe:before {
  content: "\e92b";
}
.icon-pen:before {
  content: "\e91f";
}
.icon-bell:before {
  content: "\e91e";
}
.icon-clock:before {
  content: "\e915";
}
.icon-card:before {
  content: "\e91d";
}
.icon-calendar:before {
  content: "\e912";
}
.icon-dividends:before {
  content: "\e911";
}
.icon-check:before {
  content: "\e907";
}
.icon-close:before {
  content: "\e905";
}
.icon-micro:before {
  content: "\e906";
}
.icon-book:before {
  content: "\e908";
}
.icon-user:before {
  content: "\e909";
}
.icon-wallet:before {
  content: "\e90a";
}
.icon-house:before {
  content: "\e90b";
}
.icon-notification:before {
  content: "\e90c";
}
.icon-plus:before {
  content: "\e90d";
}
.icon-minus:before {
  content: "\e90e";
}
.icon-arrow-right:before {
  content: "\e90f";
}
.icon-download:before {
  content: "\e910";
}
.icon-document:before {
  content: "\e913";
}
.icon-logout:before {
  content: "\e914";
}
.icon-arrow-bottom:before {
  content: "\e932";
}
.icon-dollar:before {
  content: "\e917";
}
.icon-arrow-back:before {
  content: "\e918";
}
.icon-list:before {
  content: "\e919";
}
.icon-building:before {
  content: "\e91a";
}
.icon-home:before {
  content: "\e91b";
}
.icon-case:before {
  content: "\e91c";
}
.icon-inst:before {
  content: "\e900";
}
.icon-fb:before {
  content: "\e901";
}
.icon-linkedin:before {
  content: "\e902";
}
.icon-tw:before {
  content: "\e903";
}
.icon-yelp:before {
  content: "\e904";
}
.icon-arrow-up:before {
  content: "\e916";
}
.icon-star-pin:before {
  content: "\e933";
}
.icon-star-pined:before {
  content: "\e934";
}
.icon-delete:before {
  content: "\e935";
}
.icon-letter:before {
  content: "\e936";
}
