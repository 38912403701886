@import "../../../assets/styles/variables";

.footer {
  margin-top: 100px;
  @media (max-width: 767px) {
    margin-top: 40px;

  }
  .h3,.h4 {
    margin-bottom: 24px;
  }
  &-top {
    padding-bottom: 80px;
    @media (max-width: 1200px) {
      padding-bottom: 60px;
    }
    .col {

    }

  }
  .row {
    @media (max-width: 1200px) {
      justify-content: space-between;
    }
  }
  &-col1,
  &-col2,
  &-col3 {
    flex: 0 0 25%;
    max-width: 25%;
    @media (max-width: 1200px) {
      flex: 0 0 auto;
      max-width: 40%;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  &-col2 {
    flex: 0 0 15%;
    max-width: 15%;
    @media (max-width: 1200px) {
      flex: 0 0 auto;
      max-width: 20%;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 24px;
    }
  }
  &-col3 {
    flex: 0 0 20%;
    max-width: 20%;
    @media (max-width: 1200px) {
      flex: 0 0 auto;
      max-width: 40%;
    }
    @media (max-width: 575px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 24px;
    }
  }
  &-col4 {
    flex: 0 0 40%;
    max-width: 40%;
    @media (max-width: 1200px) {
      flex: 0 0 100%;
      max-width: 100%;
      order: -1;
      margin-bottom: 42px;
      .muted {
        max-width: 416px;
      }
      .subscribe-block {
        max-width: 441px;
      }
    }
  }
  hr {
    border: none;
    border-color: $mainColor;
    opacity: .1;
    border-top-width: 1px;
    height: 1px;
    background: $mainColor;
    margin-bottom: 80px;
    @media (max-width: 1200px) {
      margin-bottom: 40px;
    }
  }
  &-bottom {
    background: $mainColor;
    padding: 26px 0;
    @media (max-width: 575px) {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .row {
      align-items: center;
      justify-content: space-between;
    }
    .col-copy-usn {
      flex: 0 0 100%;
      max-width: 100%;
      margin-top: 60px;
      p {
        font-size: 16px;
        line-height: 20px;
        opacity: 1;
        color: rgba($whiteColor,.5);
        a {

          font-size: 16px;
          line-height: 20px;
          color: rgba($whiteColor,.5);
          &:hover {
            color: $whiteColor;
          }
        }
      }
    }
    &-menu {
      @media  (max-width: 1200px){
        flex: 0 0 100%;
        max-width: 100%;
        order: 3;
        //margin-bottom: 24px;
      }
      @media (max-width: 575px) {
        order: 2;
        margin-top: 16px;
      }
      .menu {
        @media  (max-width: 1200px){
          //justify-content: center;
        }
        @media (max-width: 575px) {
          flex-direction: column;
        }
        li {
          &:first-child {
            a {
              @media  (max-width: 1200px){
                padding-left: 0;
              }
            }
          }
          a {
            color: rgba($whiteColor,.5);
            @media (max-width: 575px) {
              padding-left: 0;
              padding-right: 0;
              padding: 8px 0;
            }
            &:hover {
              color: $greenColor;
              span {
                &::before {
                  background: $greenColor;
                }
              }
            }
          }
        }
      }
    }
    &-copy {
      @media (max-width: 575px) {
        order: 1;
        flex: 0 0 100%;
      }
    }
    &-social {
      @media (max-width: 575px) {
        order: 3;
        flex: 0 0 100%;
        margin-top: 25px;
      }
    }
  }
  .copy {
    color: $whiteColor;
    opacity: .5;
    line-height: 20px;
    margin-bottom: 0;

  }
}
.footer-menu {
  li {
    margin-bottom: 16px;
    a {
      color: $greyColor;
      span {
        position: relative;
        i {
          position: absolute;
          font-size: 10px;
          bottom: -17px;
          left: 0;
        }
        &::before {
          background: $greenColor;
        }
      }
      &:hover {
        color: $greenColor;
      }
    }
  }
}
.social {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  padding: 0;
  li {
    padding-left: 5px;
    padding-right: 5px;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba($whiteColor,.2);
    color: $whiteColor;
    font-size: 20px;

    &:hover {
      border-color: $whiteColor;
      background: $whiteColor;
      color: $mainColor;
    }
  }
}
