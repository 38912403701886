@import "../../../assets/styles/variables";
.partners-row {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 30px;
  background: $whiteColor;
  border-radius: 20px;
  justify-content: space-between;
  @media (max-width: 1300px) {
    align-items: center;
  }
  .partners-col {
    padding: 20px 7px;
    max-width: 25%;
    flex: 0 0 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 1300px) {
      //max-width: 25%;
      //flex: 0 0 25%;
    }
    @media (max-width: 575px) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    a {
      display: block;
    }
    img {
      display: block;
      //height: 88px;
      //max-width: 150px;
      max-height: 80px;
      width: auto;
      //filter: grayscale(1);
      transition: all 400ms;
      align-self: center;
      @media (max-width: 1300px) {
        height: auto;
        width: auto;
        max-height: 48px;
        max-width: 100%;
      }
      @media (max-width: 1300px) {

      }
      &:hover {
        filter: grayscale(0);
      }
    }
  }
}
.partnerSlider {
  width: 100vw;
  .swiper-wrapper {
    transition-timing-function: linear;
  }
  .swiper-slide {
    padding: 0 50px;
    width: auto;
    img {
      max-width: 100%;
      max-height: 80px;

    }
  }
}