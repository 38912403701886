/*
Colors
 */
$mainColor: #1e2534;
$greenColor: #78bd54;
$darkGreenColor: #6FB554;
$greenGradient: linear-gradient(254.6deg, #8CCE56 0%, #57A051 100%);
$bgColor: #E5E5E5;
$mutedColor: #8E9399;
$greyColor: #8F929A;
$whiteColor: #fff;
$blackColor: #000;
$lightColor: #F2F4F5;
$yellowColor: #FFB444;
$blueColor: #6177E8;
$orangeColor: #F19048;
$redColor: #FF4141;
$darkRedColor: #DB2F2F;
$redGradient: linear-gradient(254.6deg, #FF4141 0%, #DB2F2F 100%);
$orangeGradient: linear-gradient(254.6deg, #F4A645 0%, #F06E2D 100%);

.form-col-6 {
  @media (max-width: 767px) {
    flex: 0 0 100%;
    max-width: 100%;
    & + .form-col-6 {
      margin-top: 32px !important;
    }
  }
}