@import "variables";
.ssn-verification {
  display: flex;
  flex-wrap: wrap;
  //max-width: 100%;
  margin-left: -8px;
  margin-right: -8px;
  @media (max-width: 767px) {
    margin-left: -2.5px;
    margin-right: -2.5px;
  }
  &-input {
    flex: 0 0 calc(11.11111111% - 12px);
    padding-left: 8px;
    padding-right: 8px;
    &.has-separator{
      padding-left: 0;
      flex: 0 0 calc(11.11111111% + 54px - 12px);
      display: flex;
      justify-content: space-between;
    }
    @media (max-width: 767px) {
      padding-left: 2.5px;
      padding-right: 2.5px;
      flex: 0 0 calc(11.11111111% - 11px);
      &.has-separator{
        flex: 0 0 calc(11.11111111% + 46px - 12px);
      }
    }
    @media (max-width: 600px) {
      flex: 0 0 calc(11.11111111% - 6px);
      &.has-separator{
        flex: 0 0 calc(11.11111111% + 24px - 12px);
      }
    }
  }
  &-separator {
    flex: 0 0 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
    text-align: center;
    color: #1E2534;
    opacity: 0.5;
    @media (max-width: 767px) {
      flex: 0 0 46px;
    }
    @media (max-width: 600px) {
      flex: 0 0 24px;
      font-size: 28px;
      line-height: 36px;
    }
  }
  input {
    max-width: 100%;
    display: block;
    caret-shape: underscore;
    border: none;
    width: 100%;
    height: 68px;
    background: $whiteColor;
    border-radius: 12px;
    font-weight: bold;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    transition: all 400ms;
    &.error {
      box-shadow: 0px 0px 8px 0px rgba(255, 0, 0, 0.2);
    }

    @media (max-width: 600px) {
      height: 40px;
      font-size: 24px;
      line-height: 30px;
      border-radius: 8px;
    }
  }
}

.label-bank-encrypt {
  font-size: 14px;
  line-height: 20px;
  color: #1E2534;
  display: inline-flex;
  padding: 12px 16px;
  background: #fff;
  border-radius: 12px;
  border: 1px solid rgba(30,37,52,.2);
  margin-top: 24px;
  cursor: pointer;
  & + .muted {
    font-size: 14px;
    margin-top: 8px;
    line-height: 18px;
  }
}
