@import "../../../assets/styles/variables";
.description {
  margin-top: 29px;
  p {
    font-family: "SF Pro Display";
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
  }
  a {
    font-style: italic;
    color: $greenColor;
    text-decoration: underline;
  }
}
.subscribe-form {
  position: relative;
  margin-top:  24px;
  input:not([type='submit']) {
    width: 100%;
  }
  .btn {
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    padding: 17px 38px;
    border-radius: 14px;
  }
  .form-col {
    padding-left: 0;
    padding-right: 0;
    .form-group {
      .form-group--navigation {
        display: none;
      }
      input {
        border: none !important;
      }
      &.error {
        input {
          border: none !important;
          //background: rgba($redColor,.05);
        }
      }
    }

    .error-text {
      position: absolute;
      left: 0;
      bottom: -25px;
    }

  }
  input[type='email'],
  input[type='text'],
  input[type='number'],
  input[type='password'] {
    border: none;
    padding: 23px 24px;
    padding-right: 170px;
    border-radius: 16px;
    font-size: 16px;
    line-height: 20px;
    color: $mainColor;
    background: $whiteColor;
    @media (max-width: 374px) {
      padding-right: 24px;
      margin-bottom: 0;
    }
    &::placeholder {
      font-size: 16px;
      line-height: 20px;
      transition: all 400ms;
      color: $greyColor;
    }

    &:focus {
      &::placeholder {
        opacity: 0;
        text-indent: 20px;
      }
    }
  }
  .form-group {
    @media (max-width: 374px) {
      margin-bottom: 0;
    }
  }

  .btn{
    padding: 17px 19px;
    border: none;
    border-radius: 12px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    display: inline-flex;
    align-items: center;
    &-green {
      background: $greenColor;
      color: $whiteColor;
    }
    &-apple {
      padding: 16px 41px;
      background: $mainColor;
      display: inline-flex;
      border-radius: 12px;
      img {
        align-self: center;
      }
    }
    &[disabled = 'disbled'] {
      opacity: .5;
    }
    @media (max-width: 374px) {
      position: static;
      width: 100%;
      margin-top: 8px;
      transform: translateY(0);
      text-align: center;
      justify-content: center;
    }
  }

  &.subscribe-form-wait {
    input[type='email'],
    input[type='text'],
    input[type='number'],
    input[type='password'] {
      padding-right: 180px;
    }

    .btn {

    }
  }
}

.subscribe-success {
  text-align: center;
  img {
    max-height: 118px;
  }
  .h3 {
    margin-bottom: 16px;
  }
  .btn-primary {
    margin-top: 0;
  }
  p {
    color: #8F929A;
    font-size: 14px;
    margin-bottom: 0;
  }
}
