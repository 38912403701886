@import "../../../assets/styles/variables";
.page-404 {
  display: flex;
  align-items: center;
  min-height: 100vh;
  flex-wrap: wrap;
  padding: 120px 0;
  @media (max-width: 575px) {
    padding-bottom: 40px;
  }
  .row {
    @media (max-width: 990px) {
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .col-6 {
        flex: 0 0 100%;
        max-width: 600px;
        width: 100%;
      }
    }
  }
}
.col-404-image {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 990px) {
    margin-bottom: 40px;
  }
}
.col-404-text {
  align-self: center;
  .medium {
    color: #8E9399;
    margin-bottom: 40px;
  }
}
.ill-404 {
  position: relative;
  @media (max-width: 990px) {
    max-width: 400px;

  }
  .sphere {
    max-width: 100%;
  }
  .illustration-404 {
    position: absolute;
    max-width: 640px;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    @media (max-width: 1200px) {
      max-width: 100%;
    }
    @media (max-width: 990px) {
      max-width: calc(100% + 80px);
    }
    @media (max-width: 575px) {
      max-width: 100%;
    }
  }
}