.typewrite {
  text-decoration: underline;
  text-decoration-color: #6FB554;
  color: #6FB554;
  .wrap {
    //background: linear-gradient(254.6deg, #8CCE56 0%, #57A051 100%);
    //-webkit-background-clip: text;
    //-webkit-text-fill-color: transparent;

  }
  .separator {
    animation: 1.5s opacityToggle infinite forwards;
    font-weight: 400;
  }
}