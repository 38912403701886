@import "../../../assets/styles/variables";
.header {
  padding: 32px 0;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: all 400ms;
  @media (max-width: 767px) {
    padding-top: 50px;
  }
  &.header-hidden {
    top: -160px;
    opacity: 0;
    pointer-events: none;
  }
  &-fixed {
    background: #fff;
    padding: 24px 0;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
    @media (max-width: 990px) {
      padding: 15px 0;
    }
  }
  &.menu-open {
    box-shadow: none;
    @media (max-width: 990px) {
      &.header-fixed {
        padding: 15px 0;
      }
    }
  }

  .row {
    justify-content: space-between;
    //flex-direction: row;
    //display: flex;
    //flex-wrap: unset;
  }
  &-left {
    display: flex;
    align-items: center;
    @media (max-width: 990px) {
      .header-menu {
        display: none;
      }
    }
    //flex: 0 0 calc(100% - 280px);
    //max-width: calc(100% - 300px);
  }
  &-right {
    //max-width: 280px;
    align-self: center;
    //display: flex;
    //justify-content: flex-end;
    .button-group {
      display: flex;
      align-items: center;
      margin-top: 0 !important;
      @media (max-width: 990px) {
        display: none;
      }
      .btn-primary {
        padding: 12px 32px;
        width: auto;
      }
    }
    .sign-in {
      margin-right: 38px;
      @media (max-width: 990px) {
        margin-right: 20px;
      }
    }
    .menu-trigger {
      display: none;
      width: 28px;
      height: 28px;
      position: relative;
      border: none;
      padding: 0;
      background: transparent;
      cursor: pointer;
      span {
        width: 28px;
        height: 3px;
        pointer-events: none;
        border-radius: 8px;
        background: #78BD54;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transform-origin: center center;
        transition: all 400ms;
        &:first-child {
          top: calc(50% - 5px);
        }
        &:nth-child(2) {
          top: calc(50% + 5px);
        }
      }
      &.active {
        span {
          &:first-child {
            top: 50%;
            transform: translate(-50%,-50%) rotate(-45deg);
          }
          &:nth-child(2) {
            top: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
          }
        }
      }
      @media (max-width: 990px) {
        display: block;
      }
    }
  }
  .logo {
    display: block;
    margin-right: 56px;
    img {
      display: block;
      height: 40px;
      width: auto;
    }
  }
  &-usn {
    .header-left {
      flex: 0 0 30%;
      max-width: 30%;
      .logo {
        margin-right: 20px;
        @media (max-width: 575px) {
          margin-right: 10px;
        }
        & + .usn-logo {
          border-left: 1px #D1D1D1 solid;
          padding-left: 20px;
          @media (max-width: 575px) {
            padding-left: 10px;
            img {
              height: 30px;
            }
          }
        }
      }
      @media (max-width: 575px) {

      }
    }
    .header-right {
      flex: 0 0 70%;
      max-width: 70%;
      display: flex;
      justify-content: flex-end;
      position: relative;
    }
    .usn-logo {

      img {
        max-height: 40px;
        width: auto;
      }
    }
  }
  .show-usn-disclosure {
    font-style: italic;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    padding: 0;
    color: #8F929A;
    cursor: pointer;
    &:hover {
      color: $greenColor;
    }
  }
}
.tooltip-popup {
  position: absolute;
  background: $whiteColor;
  border-radius: 16px;
  max-width: 580px;
  width: 100%;
  right: 15px;
  top: calc(100% + 24px);
  @media (max-width: 575px) {
    width: calc(100vw - 40px);
  }
  &-content {
    padding: 72px 66px;
    text-align: center;
    @media (max-width: 575px) {
      padding: 60px 32px 40px 32px;
    }
    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
    }
    p {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .close-tooltip {
    width: 25px;
    height: 25px;
    padding: 0;
    background: rgba($mainColor, .2);
    color: $whiteColor;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    right: 17.5px;
    top: 17.5px;
    transition: all 400ms;
    &:hover {
      background: $mainColor;
    }
  }
}
.menu {
  display: flex;
  flex-wrap: wrap;
  li {
    a {
      display: block;
      padding: 0 24px;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $mainColor;
      @media (max-width: 990px) {
        padding: 0 15px;
      }
      span {
        &::before {
          display: none;
        }
      }
      &:hover {
        opacity: .5;
      }
    }
  }
}
.menu-child {
  padding: 24px 32px;
  background: $whiteColor;
  box-shadow: 0px 24px 64px rgba(30, 37, 52, 0.1);
  border-radius: 16px;
  position: relative;
  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background: url(../../../assets/images/menu-icons/back-arrow.svg) center top no-repeat;
    position: absolute;
    left: 50%;
    top: -8px;
    margin-left: -5px;
  }
  li {
    a {
      display: inline-flex;
      align-items: center;
      white-space: nowrap;
      padding: 10px 0;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      color: $mainColor;
      &:hover {
        color: $greenColor;
        opacity: 1;
        span {
          &[class*='icon-'] {
            opacity: 1;
            color: $greenColor;
          }
        }
      }

      span {
        position: relative;
        i {
          position: absolute;
          left: 0;
          font-size: 10px;
          bottom: -17px;

        }
        &[class*='icon-'] {
          font-size: 20px;
          opacity: .25;
          transition: all 400ms;
          width: 32px;
          &::before {
            display: block;
          }
        }
        &.icon-universe {
          font-size: 22px;
        }
        &::before {
          background: transparent;
        }
      }
    }
  }
}
.header-menu {
  .menu-item {
    position: relative;
    &:hover {
      .menu-child-wrapp {
        opacity: 1;
        transform: translate(-50%,0);
        pointer-events: auto;
      }
    }
  }

  .menu-child-wrapp {
    position: absolute;
    top: calc(100% - 10px);
    padding-top: 27px;
    left: 50%;
    transform: translate(-50%,30px);
    transition: all 400ms;
    opacity: 0;
    pointer-events: none;
  }
}

.mobile-menu-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  height: 100%;
  height: -webkit-fill-available;
  z-index: 9;
  display: none;
  opacity: 0;
  pointer-events: none;
  transition: opacity 600ms;
  .menu-child-wrapp {
    max-height: 0;
    overflow: hidden;
    transition: all 500ms;
    opacity: 0;
    &.active {
      opacity: 1;
      max-height: 300px;
    }
  }
  @media(max-width: 990px)  {
    display: block;
  }
  &.active {
    //display: block;
    pointer-events: auto;
    opacity: 1;
  }

  .mobile-menu-inner {
    background: $whiteColor;
    height: 100%;

    overflow-y: auto;
    transition: all 400ms;
    opacity: 0;
    transform: translateY(-100px);
  }
  .mobile-menu-content {

    padding: 108px 40px 115px 40px;
    min-height: 100vh;
    position: relative;
    @media (max-width: 767px) {
      padding: 108px 20px 115px 20px;
    }
  }
  .button-group {
    position: absolute;
    bottom: 32px;
    left: 0;
    width: 100%;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    .btn-secondary,
    .btn-primary {
      flex: 0 0 calc(50% - 7.5px);
      max-width: calc(50% - 7.5px);
    }
  }
  &.active {
    .mobile-menu-inner {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.mobile-menu {
  .toggle-child {
    display: block;
    padding: 20px 30px 20px 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 18px;
    color: $mainColor;
    position: relative;
    span {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 14px;
      transition: all 400ms;
      pointer-events: none;
    }
    &.active {
      span {
        transform: rotate(-180deg) translateY(50%);
      }
    }
  }
  .menu-child {
    box-shadow: none;
    padding: 0;
    padding-bottom: 5px;
    &:not(.show) {
      //display: none;
    }
    li {
      a {
        padding: 14px 0;
        font-family: "Basier Circle";
        span {
          &[class*='icon-'] {
            font-size: 20px;

          }
        }
      }
    }
  }
}
