@import "../../../assets/styles/variables";

.popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 37, 52, 0.5);
  overflow: auto;
  z-index: 999;

  .popup {
    position: absolute;
    width: 718px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 16px;
    @media (max-width: 990px){
      max-width: 580px;
    }
    @media (max-width: 767px){
      width: calc(100% - 40px);
      max-width: 580px;
    }
    &.popup-medium {
      max-width: 920px;
      width: 100%;
    }

    &.popup-small{
      width: 580px;
      @media (max-width: 990px){
        width: calc(100% - 40px);
        max-width: 580px;
      }
      @media (max-width: 767px){
        width: calc(100% - 40px);
        max-width: 580px;
      }
      .popup-content{
        padding: 72px 66px;
        @media (max-width: 991px){
          padding: 30px;
        }
      }
    }
    @media (max-width: 991px){
      width: calc(100% - 40px);
    }

    &-content{
      padding: 72px;
      text-align: center;
      @media (max-width: 991px){
        padding: 30px;
        width: 100%;
      }
      img{
        margin-bottom: 24px;
        margin-left: auto;
        margin-right: auto;
        max-width: 100%;
      }
      h3{
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px;
      }
      a{
        font-weight: 400;
      }
      .btn-primary{
        margin-top: 32px;
        margin-right: auto;
        margin-left: auto;
      }
      .popup-list{
        text-align: left;
        ul{
          margin-top: 16px;
          li{
            margin-bottom: 16px;
            padding-left: 10px;
            position: relative;
            color: $greyColor;
            font-size: 14px;
            line-height: 18px;
            &:before{
              content: "";
              position: absolute;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $greenColor;
              top: 10px;
              left: 0;
            }
          }
        }
        &.centered{
          text-align: center;
          li{
            text-align: left;
            width: 130px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      &--tabs{
        border-radius: 16px;
        background-color: $lightColor;
        padding: 8px;
        margin-bottom: 16px;
        .current{
          display: none;
        }
        @media (max-width: 1024px){
          width: fit-content;
          margin-left: auto;
          margin-right: auto;
        }
        @media (max-width: 767px){
          margin-bottom: 20px;
          width: 100%;
          padding: 0;
          background-color: transparent;
          position: relative;
          .current{
            display: block;
            border-radius: 16px;
            background-color: $lightColor;
            position: relative;
            padding: 20px 24px;
            width: 100%;
            text-align: left;
            font-size: 14px;
            line-height: 20px;
            span{
              position: absolute;
              top: 50%;
              right: 26px;
              transform: translateY(-50%);
              font-size: 14px;
              transition: all 0.4s ease;
            }
            &.active{
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
              span{
                transform: translateY(-50%) scale(-1, -1);
              }
            }
          }
        }
        ul{
          display: flex;
          align-items: center;
          justify-content: space-between;
          @media (max-width: 1024px){
            justify-content: center;
          }
          @media (max-width: 767px){
            display: none;
            &.active {
              flex-direction: column;
              align-items: flex-start;
              position: absolute;
              z-index: 1;
              display: flex;
              width: 100%;
              background-color: $lightColor;
              padding-bottom: 10px;
              border-bottom-right-radius: 16px;
              border-bottom-left-radius: 16px;
            }
          }
          li{
            border-radius: 12px;
            padding: 12px 16px;
            font-size: 14px;
            line-height: 20px;
            @media (max-width: 767px){
              padding-left: 24px;
              width: 100%;
              text-align: left;
            }
            a{
              color: $mainColor;
              @media (max-width: 767px){
                width: 100%;
                display: block;
              }
            }
            &.active{
              background-color: $mainColor;
              @media (max-width: 767px){
                background-color: $lightColor;
                position: relative;
                &:after{
                  content: "\e907";
                  position: absolute;
                  font-size: 12px;
                  font-family: 'icomoon' !important;
                  top: 50%;
                  transform: translateY(-50%);
                  right: 24px;
                }
              }
              a{
                color: $whiteColor;
                @media (max-width: 767px){
                  color: $mainColor;
                }
              }
            }
          }
        }
      }
      &--tab-content{
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.4s ease;
        height: 0;
        &.active{
          opacity: 1;
          visibility: visible;
          height: auto;
        }
      }
      .popup-buttons{
        display: flex;

        &>*{
          margin-top: 32px;
          &:first-child{
            margin-right: 16px;
          }
        }
      }
    }
    .tab-content {
      p {
        & + ul {
          margin-top: 8px;
        }
      }
      ul {
        li {
          font-size: 14px;
          line-height: 18px;
          color: #8F929A;
        }
      }
    }
    &-buttons {
      &-between {
        justify-content: space-between;
        @media (max-width: 767px) {
          flex-wrap: wrap;
          .btn-primary {
            order: -1;
          }
          .btn-secondary {
            margin-top: 16px;
          }
        }
        [class*='btn-'] {
          flex: 0 0 calc(50% - 16px);
          margin-left: 0 !important;
          margin-right: 0 !important;
          @media (max-width: 767px) {
            flex: 0 0 100%;
            max-width: 100%;
            //margin-top: 0;
          }
        }
      }
    }
  }
  &#citizen-info {
    .popup {
      @media (min-width: 768px) and (max-width: 990px){
        max-width: calc(100% - 116px);
      }
    }
  }
  &#register-success {
    .popup-content img {
      width: 165px;
      height: auto;
    }
  }
  &.message-modal {
    .popup {
      width: calc(100% - 60px);
      max-width: 680px;
      .close {
        position: absolute;
        right: 20px;
        top: 20px;
        padding: 10px;
        font-size: 10px;
      }
    }
    .popup-content {
      padding: 40px 50px 50px 50px;
    }
    .notification-item {
      padding: 0;
    }
  }
}

.info-link {
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 2px solid #000;
  color: #000;
  font-size: 12px;
  border-radius: 50%;
  font-weight: 600;
  margin-left: 16px;
  cursor: pointer;
  transition: all 300ms ease;

  &:hover {
    color: #78BD54;
    border-color: #78BD54;
  }
}

.info-tab-ul{
  border-radius: 16px;
  background-color: #F2F4F5;
  padding: 8px;
  margin-bottom: 16px;

  ul{

    display: flex;
    align-items: center;
    justify-content: space-between;

    li{
      border-radius: 12px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 20px;
      color: #1E2534;
      cursor: pointer;
      &.active {
        background-color: #1E2534;
        color: #fff;
      }
    }
  }
}
