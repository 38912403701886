/*
Colors
 */
$mainColor: #1E2534;
$greenColor: #78BD54;
$darkGreenColor: #6FB554;
$greenGradient: linear-gradient(254.6deg, #8CCE56 0%, #57A051 100%);
$bgColor: #E5E5E5;
$mutedColor: #8E9399;
$greyColor: #8F929A;
$whiteColor: #fff;
$blackColor: #000;
$lightColor: #F2F4F5;
$yellowColor: #FFB444;
$blueColor: #6177E8;
$orangeColor: #F19048;
$redColor: #FF4141;
$darkRedColor: #DB2F2F;
$redGradient: linear-gradient(254.6deg, #FF4141 0%, #DB2F2F 100%);
$orangeGradient: linear-gradient(254.6deg, #F4A645 0%, #F06E2D 100%);

@import "../../../assets/styles/variables";
*:focus {
  outline: none;
}
a{
  text-decoration: none;
  color: inherit;
}
h1,h2,h3,h4,h5,h6,p{
  margin: 0;
}
img{
  display: block;
}
ul{
  list-style-type: none;
  margin: 0;
  padding: 0;
}
button{
  border:none;
  cursor:pointer;
  background-color: transparent;
  font-family: inherit;
}
input[type=text], input[type=email], input[type=submit], textarea{
  -webkit-appearance: none;
  outline: none;
  font-family: inherit;
}
* {
  box-sizing: border-box;
}


/*Main blocks */
body{
  margin:0;
  padding: 0;
  font-family: 'Basier Circle', sans-serif;
  background-color: #F2F4F5;
  color: $mainColor;
  font-size: 16px;
  line-height: 24px;
}
.wrapper{
  //display: flex;
  //flex-direction: column;
  //min-height: 100vh;
  //overflow-x: hidden;
}
.content{
  flex: 1 0 auto;
}
.container{
  max-width: 1220px;
  margin: 0 auto;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 1270px) {
    max-width: calc(100% - 100px);
  }
  @media (max-width: 990px) {
    max-width: 100%;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media (max-width: 767px) {
    //max-width: calc(100% - 40px);
    padding-left: 20px;
    padding-right: 20px;
  }
}
.overflow-hidden {
  overflow: hidden !important;
}
.row  {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-wrap: wrap;
}
.col {
  padding-left: 15px;
  padding-right: 15px;
}
.col-3 {
  flex: 0 0 25%;
  width: 25%;
  padding-left: 15px;
  padding-right: 15px;
  @media (max-width: 990px) {
    flex: 0 0 50%;
    width: 50%;
  }
}
.col-4 {
  flex: 0 0 33.3333%;
  width: 33.3333%;
  padding-left: 15px;
  padding-right: 15px;
}
.col-6 {
  flex: 0 0 50%;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px;
}
.text-center {
  text-align: center;
}
.pl-20px {
  padding-left: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
.text-green{
  color: $greenColor;
}
.text-dark-green{
  color: $darkGreenColor;
}
.text-red{
  color: $redColor;
}
.text-dark-red{
  color: $darkRedColor;
}
.text-yellow{
  color: $yellowColor;
}
.text-orange{
  color: $orangeColor;
}
.text-blue{
  color: $blueColor;
}
.text-grey{
  color: $greyColor;
}
.back-green{
  background-color: $darkGreenColor;
  color: $whiteColor;
}
.back-grey{
  background-color: #E8E9EB;
  color: $mainColor;
}
.section {
  .button-group {
    margin-top: 40px;
  }
  &-hero {
    .labels {
      @media (max-width: 990px) {
        display: flex;
        justify-content: center;
      }
    }
    .h1 {
      //min-height: 223px;
      @media (max-width: 1200px) {
        font-size: 56px;
        line-height: 64px;
        //min-height: 192px;
      }
      @media (max-width: 990px) {
        text-align: center;
        max-width: 522px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 24px;
      }
      @media (max-width: 575px) {
        font-size: 40px;
        line-height: 48px;
        max-width: 335px;
        font-weight: 700;
        min-height: 144px;
      }
    }
    .muted {

      //text-decoration: underline;
      text-decoration-color: #78BD54;
      @media (max-width: 990px) {
        margin-left: auto;
        margin-right: auto;
        max-width: 416px;
        text-align: center;
      }
    }
    .button-group {
      @media (max-width: 990px) {
        text-align: center;
        margin-top: 24px;
      }
    }
    .typewrite {
      text-decoration: underline;
      text-decoration-color: #6FB554;
      color: #6FB554;
      .wrap {
        //background: linear-gradient(254.6deg, #8CCE56 0%, #57A051 100%);
        //-webkit-background-clip: text;
        //-webkit-text-fill-color: transparent;

      }
      .separator {
        animation: 1.5s opacityToggle infinite forwards;
        font-weight: 400;
      }
    }
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(123.59deg, #8CCE56 0%, rgba(140, 206, 86, 0) 50.08%), linear-gradient(236.27deg, #8CCE56 0%, rgba(140, 206, 86, 0) 49.92%);;
      opacity: .1;
      //background: url(../images/bg.svg) center bottom no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
      pointer-events: none;
      @media (max-width: 990px) {
        background: linear-gradient(236.27deg, #8CCE56 0%, rgba(140, 206, 86, 0) 49.92%);
        //height: 252px;
      }
    }
    .container {
      position: relative;
      flex: 0 0 100%;
      width: 100%;
    }
    .section-inner {
      padding: 200px 0 145px 0;
      min-height: 100vh;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      //overflow: hidden;
      @media (max-width: 990px) {
        padding-bottom: 0px;
        align-items: flex-start;
        padding-top: 158px;

      }
    }
    &-info {
      max-width: 540px;
      width: 100%;
      @media  (max-width: 1200px){
        max-width: 50%;
      }
      @media (max-width: 990px) {
        max-width: 600px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
      }
    }
    &-illustration {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 40px;
      @media (max-width: 1200px) {
        left: 55%;
      }
      @media (max-width: 990px) {
        position: static;
        left: auto;
        top: auto;
        transform: translate(0,0);
        max-width: 535px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 64px;
      }
      @media (max-width: 575px) {
        max-width: 100%;
      }
      img {
        //max-height: calc(100vh - 100px);
      }
    }
  }
  &-partners {
    .section-inner {
      padding: 40px 0 60px 0;
      @media (max-width: 575px) {
        padding: 0 0 40px 0;
      }
    }
  }
  &-benefits {
    .section-inner {
      padding: 60px 0 40px 0;
      @media (max-width: 575px) {
        padding-bottom: 20px;
        padding-top: 40px;
      }
    }
    .section-title {
      margin-bottom: 64px;
      @media (max-width: 990px) {
        margin-bottom: 40px;
      }
    }
    .row {
      margin-left: -10px;
      margin-right: -10px;
    }
    .benefit-col {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 20px;
      .item {
        border: 2px transparent solid;
        cursor: pointer;
        transition: all 400ms;
        &:hover {
          border-color: #6FB554;
        }
      }
      &.col-4 {
        @media (max-width: 767px) {
          flex: 0 0 50%;
          max-width: 50%;
          width: 50%;
        }
      }
      &.col-3 {
        @media (max-width: 575px) {
          flex: 0 0 100%;
          max-width: 100%;
          width: 100%;
          h4, .h4 {
            font-size: 20px;
            margin-bottom: 8px;
            font-weight: 700;
          }
        }
      }
    }
  }
  &-overview {
    @media (max-width: 575px) {
      overflow: hidden;
    }
    .section-inner {
      padding: 60px 0;
    }
  }
}
@keyframes opacityToggle {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fp-section {
  @media(max-width: 990px)  {
    .mobile-menu-wrapper,
    .header {
      display: none !important;
    }
  }
}

.grey-label {
  padding: 8px 16px;
  background: rgba(30, 37, 52, 0.15);
  border-radius: 12px;
  display: inline-flex;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: $whiteColor;
  margin-bottom: 40px;
  text-align: center;
  @media (max-width: 990px) {
    margin-left: auto;
    margin-right: auto;
    align-self: center;
  }
}

.header-scroll {
  opacity: 0;
  pointer-events: none;
  transition: opacity 400ms;
  position: relative;
  z-index: 20;

  @media (min-width: 990px) {
    .header {
      padding: 24px 0;
      background: #fff;
      box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
      top: -100px;
    }
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
    @media (min-width: 990px) {
      .header {
        top: 0;
      }
    }
  }
  @media(max-width: 990px)  {
    opacity: 1;
    pointer-events: auto;
  }
}
.section-title {
  &-top {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    color: $darkGreenColor;
    margin-bottom: 16px;
  }
  &-main {
    @media (max-width: 990px) {
      br {
        display: none;
      }
    }
    @media (max-width: 575px) {

    }
  }
}
.title-top {
  text-transform: uppercase;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: $darkGreenColor;
  margin-bottom: 16px;
  @media (max-width: 575px) {
    font-size: 14px;
    line-height: 24px;
  }
}
.benefit-col {
  a.item {
    border: 2px solid transparent;
    &:hover {
      border: 2px solid #6FB554;
    }
    .benefit-image {
      min-height: 124px;
      display: flex;
      align-items: center;
      justify-content: center;
      @media (max-width: 990px) {
        min-height: 121px;
      }
      @media (max-width: 575px) {
        min-height: 66px;
        margin-bottom: 18px;
      }
    }
    img {
      max-height: 124px;
      align-self: center;
      @media (max-width: 990px) {
        max-height: 121px;
        width: auto;
      }
      @media (max-width: 575px) {
        max-height: 66px;
      }
    }
  }
  .item {
    background: $whiteColor;
    height: 100%;
    border-radius: 16px;
    padding: 32px 28px;
    text-align: center;
    display: block;
    h5 {
      margin-top: 32px;
    }
    @media (max-width: 767px) {
      padding: 20px;
    }
    img {
      max-width: 100%;
      max-height: 124px;
      display: inline-block;
    }
    &-image {
      text-align: center;
      margin-bottom: 32px;
      @media (max-width: 575px) {
        img {
          max-width: 192px;
          max-height: 143px;
          width: auto;
          height: auto;
        }
      }
    }
    &-info {
      margin-top: 32px;
      p {
        color: $greyColor;
        margin-bottom: 0;
      }
    }
  }
}

.section-partners {
  .section-title {
    text-align: center;
    .subtitle {
      text-transform: uppercase;
      color: $greenColor;
      margin-bottom: 16px;
    }

    margin-bottom: 40px;
  }
}
.benefit {
  background: $whiteColor;
  height: 100%;
  border-radius: 16px;
  padding: 32px 28px;
  text-align: center;
  display: block;
  @media (max-width: 767px) {
    padding: 20px;
  }
  img {
    max-width: 100%;
    display: inline-block;
    max-height: 143px;
  }
  &-image {
    text-align: center;
    margin-bottom: 32px;
    @media (max-width: 575px) {
      img {
        max-width: 192px;
        max-height: 143px;
        width: auto;
        height: auto;
      }
    }
  }
  &-info {
    .h4 {
      margin-bottom: 8px;
    }
    p {
      color: $greyColor;
      margin-bottom: 0;
    }
  }
}

.overview-blocks,
.fp-overview {
  display: flex;
  flex-wrap: wrap;
  .overview-images {
    position: sticky;
    flex: 0 0 50%;
    max-width: 480px;
    width: 50%;
    height: 582px;
    transform: translateY(0%);
    //transition: all 400ms;
    &.sticky {
      position: sticky;
      top: 50%;
      transform: translateY(-50%);
    }
    &.no-translate {
      //transform: translateY(0%);
    }
    .round-bg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }
  }
  .overview-image {
    position: relative;
    z-index: 2;
    &-main {
      max-height: 582px;
      width: auto;
      height: auto;
    }
  }
  .overview-infos {
    flex: 0 0 50%;
    max-width: 50%;
    padding-left: 94px;
    @media (max-width: 990px) {
      flex: 0 0 100%;
      max-width: 100%;
      padding-left: 0px;
    }
    .overview-info {
      max-width: 520px;
      min-height: 580px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (max-width: 990px) {
        max-width: 565px;
      }
    }
  }
}
.fp-overview {
  position: fixed;
  top: calc(50% + 50px) !important;
  width: 100%;
  left: 0;
  transform: translateY(-50%);
  pointer-events: none;
  opacity: 0;
  transition: opacity 400ms;
  @media (max-width: 990px) {
    display: none !important;
  }
  &.active {
    opacity: 1;
  }
  .container {
    width: 100%;
    flex: 0 0 100%;
  }
  .phone-placeholder {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%) rotate(-5deg);
    width: 278px;
    .mockup-bg {
      display: block;
      width: 100%;
      position: relative;
      z-index: 5;
    }
  }
  .overview-animation-image {
    position: absolute;
    opacity: 0;
    transition: all 400ms;
    &.active {
      //opacity: 1;
    }
    &-content {
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      width: 101%;
      margin-left: 0;
      z-index: 4;

      border-radius: 50px;
      &::before {
        width: 96%;
        height: 96%;
        content: '';
        position: absolute;
        left: 2%;
        top: 2%;
        box-shadow: -40px 40px 80px 0px rgba(30, 37, 52, 0.15);
        border-radius: 50px;
      }
      img {
        width: 100%;
        position: relative;
        z-index: 1;
      }
    }
    &-cart {
      z-index: 3;
      left: -100px;
      top: 40%;
      transform: translateY(40px) scale(.8,.8);
      img {
        width: 243px;
        height: auto;
      }
    }
    &-cart2 {
      z-index: 6;
      left: 65px;
      top: 27%;
      transform: translateX(-40px) scale(.8,.8);
      img {
        width: 257px;
        height: auto;
      }
    }
    &-emoji2,
    &-emoji {
      z-index: 7;
      right: -70px;
      top: 45%;
      transform: translateY(-40px) scale(.8,.8);
      img {
        width: 40px;
        height: auto;
      }
    }

    // Overview  2
    &-7 {
      left: -125px;
      top: 60%;
      img {
        width: 252px;
      }
    }
    &-8 {
      left: 147px;
      top: 51%;
      img {
        width: 147px;
      }
    }
    &-9 {
      left: -50px;
      right: auto;
      top: 40%;
    }
    &-10 {
      right: -90px;
      top: 37%;
    }

    // Overview  3
    &-12 {
      z-index: 3;
      left: -100px;
      top: 50%;
      img {
        width: 189px;
      }
    }
    &-13 {
      z-index: 6;
      top: 71%;
      left: 60px;
      img {
        width: 251px;
      }
    }

    // Overview  4
    &-16 {
      img {
        width: 106px;
      }
    }
    &-18 {
      top: 35%;
    }
    &-17,
    &-19,
    &-20 {
      transform: translate(-30px,30px) scale(.8,.8);
      img {
        width: 80px;
      }
    }
    &-20 {
      left: calc(100% + 50px);
      top: 45%;
      z-index: 9;
    }
    &-19 {
      left: calc(100% + 36px);
      top: calc(45% + 37px);
      z-index: 8;
    }
    &-17 {
      left: calc(100% + 20px);
      top: calc(45% + 74px);
      z-index: 7;
    }



    &.active {
      &.overview-animation-image-content {
        opacity: 1;
      }
      &.overview-animation-image-cart {
        opacity: 1;
        transform: translateY(0px) scale(1,1);
      }
      &.overview-animation-image-cart2 {
        opacity: 1;
        transform: translateX(0px) scale(1,1);
      }
      &.overview-animation-image-emoji2,
      &.overview-animation-image-emoji {
        opacity: 1;
        transform: translateY(0px) scale(1,1);
      }
      &.overview-animation-image-17,
      &.overview-animation-image-19,
      &.overview-animation-image-20 {
        opacity: 1;
        transform: translate(0px,0px) scale(1,1);
      }
    }
  }
}
.section-overview-fp {
  @media (max-width: 990px) {
    height: auto !important;
  }
  .overview-images {
    display: none;
    .mockup-bg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%)rotate(-5deg);
      width: 275px;
      z-index: 4;
      height: auto;
      box-sizing: border-box;
      display: block;
      @media (max-width: 575px) {
        width: 194px;
      }
    }
    @media (max-width: 990px) {
      display: block;
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      transform: translate(0,0);
      min-height: 510px;
      width: 100%;
    }
    @media (max-width: 575px) {
      max-width: 340px;
      min-height: 405px;
      height: 405px;
      .round-bg {
        width: 335px;
        height: auto;
      }
    }
  }
  .overview-blocks {
    justify-content: flex-end;
    @media (max-width: 990px) {
      justify-content: center;
      align-items: flex-start;
      flex-direction: column-reverse;
      //display: block;
      .overview-images {
        margin-left: auto;
        margin-right: auto;
      }
    }
    @media (max-width: 990px) {

    }
    .overview-infos {

      .overview-info {
        @media (max-width: 990px) {
          padding:  0 0 60px 0;
          min-height: 0;
        }
      }
    }
  }
  .section-overview {
    min-height: 100vh;
    display: flex;
    align-items: center;
    padding-top: 50px;
    @media (max-width: 990px) {
      min-height: 0;
      padding-top: 0;
    }
    .section-inner {
      flex: 0 0 100%;
      @media (max-width: 990px) {
        padding: 40px 0 50px 0;
      }
    }
  }
}

@keyframes opacityTranslateAnim {
  from {
    opacity: 0;
    transform: translate(-50%,calc(-50% + 50px)) scale(.8,.8) rotate(0deg);
  }
  to {
    opacity: 1;
    transform: translate(-50%,-50%) scale(1,1) rotate(-5deg);
  }
}
@keyframes opacityTranslateRightAnim {
  from {
    opacity: 0;
    transform: translateX(100px) rotate(0deg);
  }
  to {
    opacity: 1;
    transform: translateX(0) rotate(-5deg);
  }
}
@keyframes opacityTranslateLeftAnim {
  from {
    opacity: 0;
    transform: translateX(-100px) rotate(0deg);
  }
  to {
    opacity: 1;
    transform: translateX(0) rotate(-5deg);
  }
}
@keyframes bounceIn{
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
}

.overview-image {
  height: 100%;
  position: absolute !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  transition: all 400ms;
  opacity: 0;
  &.active {
    opacity: 1;
    .overview-animation {
      .mockup-bg {
        animation: .7s opacityTranslateAnim forwards;
        animation-delay: .1s;
      }
      .overview-animation-image {
        &-1 {
          animation: .7s opacityTranslateAnim forwards;
          animation-delay: .1s;
        }
        &-2 {
          animation: .7s opacityTranslateRightAnim forwards;
          animation-delay: .2s;
        }
        &-3 {
          animation: .7s opacityTranslateRightAnim forwards;
          animation-delay: .3s;

        }
        &-4 {
          animation: .7s bounceIn forwards;
          animation-delay: .4s;
        }
        &-5 {
          animation: .7s bounceIn forwards;
          animation-delay: .5s;
        }
        &-6 {}
      }
    }
  }
  .overview-animation {
    height: 100%;
    width: 100%;
    .mockup-bg {
      transform: translate(-50%,calc(-50% + 50px)) scale(.8,.8) rotate(0deg);
      opacity: 0;
    }
    .overview-animation-image {
      position: absolute;
      //transition: all 400ms;
      &-1 {
        left: 50%;
        top: 50%;
        transform: translate(-50%,calc(-50% + 50px)) scale(.8,.8) rotate(0deg);
        z-index: 3;
        opacity: 0;
        @media (max-width: 575px) {
          width: 194px;
        }
        &::before {
          box-shadow: -40px 40px 80px 0px rgba(30, 37, 52, 0.15);
          border-radius: 50px;
          content: '';
          position: absolute;
          left: 10px;
          top: 10px;
          width: calc(100% - 20px);
          height: calc(100% - 20px);
          @media (max-width: 575px) {
            box-shadow: -20px 20px 60px 0px rgba(30, 37, 52, 0.15);
          }
        }
        img {
          width: 278px;
          height: auto;
          position: relative;
          @media (max-width: 575px) {
            width: 102%;
            margin-left: -1%;
            //margin-left: 2px;
          }
        }
      }
      &-2 {
        left: 5%;
        top: 50%;
        transform: translateX(100px) rotate(0deg);
        opacity: 0;
        img {
          width:243px;
          @media (max-width: 575px) {
            width: 140px;
          }
        }
      }
      &-3 {
        z-index: 4;
        left: 30%;
        //transform: rotate(-5deg);
        top: 27.5%;
        transform: translateX(100px) rotate(0deg);
        opacity: 0;
        @media (max-width: 990px) {
          top: 23.5%;
        }
        @media (max-width: 575px) {
          top: 33.5%;
        }
        img {
          max-width: 257px;
          @media (max-width: 575px) {
            width: 180px;
          }
        }
      }
      &-4 {
        left: 5%;
        top: 40.5%;
        opacity: 0;
        img {
          max-width: 40px;
        }
      }

      &-5 {
        left: 90%;
        top: 42%;
        z-index: 5;
        opacity: 0;
        img {
          max-width: 40px;
        }
      }
      &-6 {
        opacity: 0;
      }

    }
  }
  &-2 {
    .overview-animation {
      .overview-animation-image {
        &-1 {

          img {

          }
        }
        &-2 {
          left: 0%;
          top: 65%;
          img {
            width:243px
          }
          @media (max-width: 575px) {
            top: 60%;
          }
        }
        &-3 {
          z-index: 4;
          left: 53%;
          transform: rotate(-5deg);
          top: 50.5%;
          @media (max-width: 575px) {

          }
          img {
            max-width: 147px;
            @media (max-width: 575px) {
              width: 102px;
            }
          }

        }
        &-4 {
          //top: ;
        }

        &-5 {
          left: 84%;
          top: 28%;
          z-index: 5;
          img {
            max-width: 40px;
          }
        }

      }
    }
  }
  &-3 {
    .overview-animation {
      .overview-animation-image {
        &-1 {

          img {

          }
        }
        &-2 {
          left: 0%;
          top: 55%;
          img {
            width:189px;
            @media (max-width: 575px) {
              width: 140px;
            }
          }
        }
        &-3 {
          z-index: 4;
          left: 33%;
          top: 70%;
          transform: rotate(-5deg);
          @media (max-width: 575px) {
            top: 65%;
          }
          img {
            max-width: 251px;
            @media (max-width: 575px) {
              width: 175px;
            }
          }
        }

        &-5 {
          left: 90%;
          top: 48%;
          z-index: 5;
          img {
            max-width: 40px;
          }
        }

      }
    }
  }
  &-4 {
    .overview-animation {
      .overview-animation-image {
        &-1 {

          img {

          }
        }
        &-2 {
          left: 3%;
          top: 45%;
          img {
            width:106px
          }
        }
        &-3 {
          z-index: 4;
          left: 80%;
          top: 56%;
          transform: rotate(-5deg);
          img {
            max-width: 80px;
          }
        }
        &-4 {
          z-index: 4;
          left: 83%;
          top: 49%;
          transform: rotate(-5deg);
          img {
            max-width: 80px;
          }
        }
        &-6 {
          z-index: 4;
          left: 86%;
          top: 42%;
          transform: rotate(-5deg);
          img {
            max-width: 80px;
          }
        }

        &-5 {
          left: 85%;
          top: 30%;
          z-index: 5;
        }

      }
    }
    &.active {
      .overview-animation {
        .overview-animation-image {
          .overview-animation-image-3 {

          }

        }
      }

    }
  }
}
@keyframes cartAnimation {
  from {
    opacity: 0;
    transform: translate(-20px,20px) rotate(0deg);
  }
  to {
    opacity: 1;
    transform: translate(0px,0px) rotate(-5deg);
  }
}
.overview-image-4.active .overview-animation .overview-animation-image-6 {
  animation: 1s cartAnimation forwards;
  animation-delay: .6s;
}
.overview-image-4.active .overview-animation .overview-animation-image-4 {
  animation: 1s cartAnimation forwards;
  animation-delay: .5s;
}
.overview-image-4.active .overview-animation .overview-animation-image-3 {
  animation: 1s cartAnimation forwards;
  animation-delay: .4s;
}

.overview-info {
  .h1 {
    @media (max-width: 1200px) {
      font-size: 56px;
      line-height: 64px;
    }
    @media (max-width: 575px) {
      font-size: 40px;
      line-height: 48px;
      font-weight: 700;
    }
  }
}
